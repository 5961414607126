import {
    MDBAnimation,
    MDBCol, MDBRow, MDBContainer, MDBCardTitle
} from 'mdb-react-ui-kit';

import React, {useState} from "react";
const MariaPage = () => {

    // `useState` returns an array with the state
    // and the method used to update the state
    // You can initialise the state with a variable/object
    const [title, setTitle] = useState('Bronze');

    return (
            <MDBAnimation type='zoomIn' duration='500ms'>
                <div className="white container my-5 ">

                    <section className="dark-grey-text">

                        <div className="row pr-lg-5">
                            <div className="col-md-5 mb-4">

                                <div className="view">
                                    <img src="https://mdbootstrap.com/img/illustrations/graphics(4).png"
                                         className="img-fluid" alt="smaple image"/>
                                </div>

                            </div>
                            <div className="col-md-7 d-flex align-items-center">
                                <MDBContainer>
                                    <MDBCardTitle>Call Forwarding and SMS notifications</MDBCardTitle>
                                    <MDBRow>
                                        <MDBCol>
                                            <p>
                                                Here is the common business situation. Maria is the business owner. She sells studio recording equipment. Business is doing great. Maria has many happy customers.
                                            </p>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol>
                                            <p>
                                                Once in a while, some of these customers have technical issues with the equipment. Maria wants to keep her customers happy. She establishes a support phone line. All calls to that line get forwarded to one of her support personnel: Joe or Daniel. Joe and Daniel each have personal cell phones.
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol>
                                            <p>
                                                Elen is a customer. She calls a support line. However,  Joe and Daniel are assisting other customers. Hence, they can’t pick up a call. Remember, Maria wants to keep her customers happy. Daniel, who has missed a call, receives a text with Elen’s phone number informing him that he missed a call. He calls back to Elen. Elen is happy. Maria is happy as well! She buys the phone line from btelb.com, and we take care of the rest!
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol>
                                            <p>
                                                Elen is happy. Maria is happy as well! She buys the phone line from btelb.com, and we take care of the rest!
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>

                            </div>
                        </div>

                    </section>

                </div>
            </MDBAnimation>

    )


};

export default MariaPage;