import {
    MDBCol, MDBRow, MDBContainer, MDBCardTitle
} from 'mdb-react-ui-kit';
import React, {useState} from "react";
const FeaturePage = ({img, title, text}) => {

    let image = img != null ? (                            <div className="view">
        <img src={img}
             className="img-fluid" alt="smaple image"/>
    </div>) :
        "";
    return (
            <div className="white container my-5 ">

                <section className="dark-grey-text">

                    <div className="row pr-lg-5">
                        <div className="col-md-5 mb-4">

                            {image}

                        </div>
                        <div className="col-md-7 d-flex align-items-center">
                            <MDBContainer>
                                <MDBCardTitle>{title}</MDBCardTitle>
                                <MDBRow>
                                    <MDBCol>
                                        <p>
                                            {text}
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>

                        </div>
                    </div>

                </section>

            </div>
    )

};

export default FeaturePage;