
import React, {useState} from 'react';
import {
    MDBCol,
    MDBRow
} from 'mdb-react-ui-kit';

import SearchPhonePnl from "./SearchPhonePnl";
import ResultTablePnl from "./ResultTablePnl";

/**
 * This page allows to search phone numbers and buy those
 *
 * For example of synchronizing 2 sibling components
 *
 * https://stackoverflow.com/questions/61836485/passing-data-to-sibling-components-with-react-hooks
 * https://itnext.io/passing-data-between-sibling-components-in-react-using-context-api-and-react-hooks-fce60f12629a
 *
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */

/**
 * Create a context
 * @type {React.Context<{phones: null, setPhones: setPhones}>}
 */
export const Context = React.createContext({ phones: null, setPhones: () => {} });

const BuyPhonesPage = ({ title }) => {
    const [phones, setPhones] = useState(null);

    return (
        <>
            <MDBAnimation type='zoomIn' duration='500ms'>
                <MDBFreeBird>
                    <MDBRow>
                        <MDBCol md='8' className='mx-auto'>
                            <MDBJumbotron className='mt-3'>
                                <Context.Provider value={{phones, setPhones}}>
                                    <SearchPhonePnl/>
                                    <ResultTablePnl/>
                                </Context.Provider>
                            </MDBJumbotron>
                        </MDBCol>
                    </MDBRow>
                </MDBFreeBird>
            </MDBAnimation>
        </>
    )
}

export default BuyPhonesPage;