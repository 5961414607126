import React, {useContext} from 'react';
import { Route, Switch } from 'react-router-dom';
import PricingPage from "./pages/PricingPage";
import PhonesPage from "./pages/phones/BuyPhonesPage";
import HomePage from "./pages/home/HomePage";
import BuyPhonesPage from "./pages/phones/BuyPhonesPage";
import GetStarted from "./pages/getstarted/GetStarted";
import FAQPage from "./pages/faq/FAQPage";
// import UserContext from './providers/UserProvider';
import MariaPage from "./pages/teasers/MariaPage";
import ContactPage from "./pages/contact/ContactPage";
import VoicePage from "./pages/voice/VoicePage";
import SignUpPage from "./pages/signup/SignUpPage";
import Dashboard from "./pages/account/Dashboard";

const Routes = () => {
        // const userContext = useContext(UserContext);

        // let home = userContext !== undefined ?
        //     <Route exact path='/' component={HomePage} />
        //     :
        //     <Route exact path='/' component={VoicePage} />

        let home =  <Route exact path='/' component={VoicePage} />

        return (
            <Switch>
                {home}
                <Route exact path='/home' component={HomePage} />
                <Route exact path='/voice' component={VoicePage}/>
                <Route exact path='/getstarted' component={GetStarted}/>
                <Route exact path='/signup' component={SignUpPage}/>
                {/*<Route exact path='/addline' component={AddLine}/>*/}
                <Route exact path='/pricing' component={PricingPage} />

                <Route exact path='/contactus' component={ContactPage} />
                {/*<Route exact path='/signin' component={SignInPage} />*/}



                <Route exact path='/buyphones' component={BuyPhonesPage} />
                <Route exact path='/faq' component={FAQPage} />
                <Route exact path='/maria' component={MariaPage}/>
                <Route exact path='/signup' component={SignUpPage}/>
                <Route exact path='/dashboard' component={Dashboard}/>
                <Route
                    render={function() {
                        return <h1>Not Found</h1>;
                    }}
                />
            </Switch>
        )
}

export default Routes;
