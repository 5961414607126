/**
 * This page allows to search phone numbers and buy those
 *
 * For example of synchronizing 2 sibling components
 *
 * https://stackoverflow.com/questions/61836485/passing-data-to-sibling-components-with-react-hooks
 * https://itnext.io/passing-data-between-sibling-components-in-react-using-context-api-and-react-hooks-fce60f12629a
 *
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */

/**
 * Display options to signin with using supported providers.
 * At the moment, provider can be Google or Email and Password
 */

import '../HomePage.css';
import React, {useContext} from "react";
import {MDBStepper, MDBStepperStep, MDBStepperHead, MDBStepperContent} from "mdb-react-ui-kit";
// import {UserContext} from "../../providers/UserProvider";

const SignUpPage = () => {
    // const user = useContext(UserContext);

    return (
        <MDBStepper>
            <MDBStepperStep itemId={1}>
                <MDBStepperHead icon='1' text='step1' />
                <MDBStepperContent>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua
                </MDBStepperContent>
            </MDBStepperStep>

            <MDBStepperStep itemId={2}>
                <MDBStepperHead icon='2' text='step2' />
                <MDBStepperContent>
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat
                </MDBStepperContent>
            </MDBStepperStep>

            <MDBStepperStep itemId={3}>
                <MDBStepperHead icon='3' text='step3' />
                <MDBStepperContent>
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                </MDBStepperContent>
            </MDBStepperStep>
        </MDBStepper>
    );
}


export default SignUpPage;