import React, {useState} from "react";
import {MDBAnimation, MDBCol, MDBCardImage, MDBCardBody,
    MDBCardTitle, MDBCardText, MDBCard, MDBContainer,
        MDBRow} from "mdb-react-ui-kit";

import '../HomePage.css';

import { useHistory } from "react-router-dom";

const HomePage = ({ title }) => {
    let history = useHistory();

    const getPhones = (event) => {
        event.preventDefault();
        history.push('/buyphones')
    }

    return (
        <>
            {/*<MDBEdgeHeader color='blue darken-3' className="d-none" />*/}
            <MDBAnimation type='zoomIn' duration='500ms'>
                {/*<MDBFreeBird>*/}
                    {/*<MDBJumbotron className='mt-3 w-100' >*/}
                    <MDBRow>
                        <MDBCol>

                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol md="4">
                            <MDBContainer>
                                <MDBCard className="card-body" style={{ width: "18rem", marginTop: "1rem" }}>
                                    <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Mockups/Lightbox/Thumbnail/img%20(67).jpg"
                                                  waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Call Forwarding</MDBCardTitle>
                                        <MDBCardText>
                                            Get a local phone number with the call forwarding to your cell phone,
                                            voicemail, you will receive a text message if you missed a call with call back number
                                            of the customer. Additionally, we can record your conversation if you need to.
                                        </MDBCardText>
                                        <div className="flex-row">
                                            <a href='/buyphones' >Get a Phone</a>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBContainer>
                        </MDBCol>

                        <MDBCol md="4">
                            <MDBContainer>
                                <MDBCard className="card-body" style={{ width: "18rem", marginTop: "1rem" }}>
                                    <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Mockups/Lightbox/Thumbnail/img%20(67).jpg"
                                                  waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Voice Recording</MDBCardTitle>
                                        <MDBCardText>
                                            Record conversation with your customer
                                        </MDBCardText>
                                        <div className="flex-row">
                                            <a href='/buyphones' >Get a Phone</a>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBContainer>
                        </MDBCol>

                        <MDBCol md="4">
                            <MDBContainer>
                                <MDBCard className="card-body" style={{ width: "18rem", marginTop: "1rem" }}>
                                    <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Mockups/Lightbox/Thumbnail/img%20(67).jpg"
                                                  waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>SMS Notifications</MDBCardTitle>
                                        <MDBCardText>
                                            Record conversation with your customer
                                        </MDBCardText>
                                        <div className="flex-row">
                                            <a href='/buyphones' >Get a Phone</a>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBContainer>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol md='8' className='mx-auto'>
                        </MDBCol>
                    </MDBRow>
                    {/*</MDBJumbotron>*/}
                {/*</MDBFreeBird>*/}
            </MDBAnimation>
        </>
    )
}

export default HomePage;