import React from 'react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';

const PricingTable = () => {
    return (<div></div>
        // <MDBTable>
        //     <caption>Voice Prices</caption>
        //     <MDBTableHead>
        //         <tr>
        //             <th>Feature</th>
        //             <th>Cost</th>
        //             <th>Info</th>
        //         </tr>
        //     </MDBTableHead>
        //     <MDBTableBody>
        //         <tr>
        //             <td>Local number</td>
        //             <td>$4.99 </td>
        //             <td>per line per month</td>
        //         </tr>
        //         <tr>
        //             <td>toll free minutes</td>
        //             <td>$0.039</td>
        //             <td>
        //                 <tr>
        //                     call forwarding to your private line
        //                 </tr>
        //                 <tr> SMS with caller's phone number for missed calls</tr>
        //                 <tr> Voicemail</tr>
        //             </td>
        //         </tr>
        //         <tr>
        //             <td>Call Recording</td>
        //             <td>$0.0199</td>
        //             <td>per min</td>
        //         </tr>
        //         <tr>
        //             <td>Storage</td>
        //             <td>$0.0099</td>
        //             <td>per min per month</td>
        //         </tr>
        //     </MDBTableBody>
        // </MDBTable>
    );
}

export default PricingTable;