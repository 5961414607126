import React, {useEffect, useState, useContext} from "react";
import { MDBCol, MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import {Context} from "./BuyPhonesPage";

const SearchPhonePnl = () => {
    const {setPhones} = useContext(Context);
    const [areaCode, setAreaCode] = useState('312');


    useEffect(() => {
        console.log("Search Phone::useEffect");
        // console.log(phones);
    });

    const input = (event) => {
        event.preventDefault();
        console.log("input called");
        var value = event.target.value;
        console.log("input: " + value)

        if (value.length === 3){
            setAreaCode(value);
        }
    }
    const search = (event) => {
        event.preventDefault();
        console.log("search called")

        // query api and update the context
        fetch(`/api/v2/phones?areaCode=${areaCode}&page_token=1&page_size=20`)
            .then(response => response.json())
            .then(phones => {
                var tableData = phones.map( function(phone) {
                    var info = { "phone": phone.friendlyName.endpoint,
                        "city": phone.locality,
                        'handle': <MDBBtn color="purple" size="sm">Buy</MDBBtn>
                    }
                    return info;
                });
                setPhones(tableData);
            } );
    }

    return (
        <MDBCol md="6">
            <MDBFormInline className="md-form">
                <input onInput = {(event) => {input(event)}} className="form-control form-control-sm ml-3 w-75" type="text" placeholder="Search by area code, e.g. 354" aria-label="Search" />
                <MDBIcon onClick = {(event) => {search(event)}} icon="search" />
            </MDBFormInline>
        </MDBCol>
    );
}

export default SearchPhonePnl;