import React from "react";
import {MDBAnimation, MDBCol,  MDBContainer, MDBRow} from "mdb-react-ui-kit";

import '../HomePage.css';
import solutions from "../../assets/solutions.svg"
import messaging from "../../assets/messaging.svg"
import pricing from "../../assets/pricing.svg"
import analytics from  "../../assets/analytics.svg"

import { useHistory } from "react-router-dom";
import MariaTeaser from "../teasers/MariaTeaser";
// import SolutionPills from "../teasers/SolutionPills";
import FeaturePage from "../teasers/FeaturePage";
import PricingPage from "../PricingPage";
const VoicePage = ({ title }) => {
    const scrollToTop = () => window.scrollTo(0, 0);

    // let history = useHistory();

    const getPhones = (event) => {
        event.preventDefault();
        history.push('/buyphones')
    }
    let beevoiceText = "BeeVoice©  is a comprehensive, cost-optimized, and highly-performant phone solution that accelerates the growth of your business."
    let callForwardText = "BeeVoice is the ideal phone solution for your business.  It automatically handles phone calls by forwarding them  to your cell phone or other private  number. This creates an opportunity for your business to establish a presence in different geographic areas and LLCrease your customer base.";
    let smsNotificationsText = "BeeVoice provides a myriad of capabilities such as SMS notifications with caller’s id,  conversation recording, and call forwarding to multiple lines. If you, one of your technicians, or support representatives misses a call, you will receive an SMS with a callback number of your customer. Call them before they go somewhere else.";
    let payAsYouGoText = "Demand for your services may fluctuate. Focus on your business without worrying about the cost. Pay as you go model scales together with your business,  only pay for minutes that you use. ";
    return (
        <>
            <MDBAnimation type='zoomIn' duration='500ms'>
                <MDBContainer  >
                    {/*<MDBRow >*/}
                    {/*    <MDBCol className="mt-2">*/}
                    {/*        <img src={mindset} className="img-fluid" alt="" />*/}
                    {/*    </MDBCol>*/}
                    {/*</MDBRow>*/}
                    <MDBRow>
                        <FeaturePage title={"BeeVoice"} text={beevoiceText} img={analytics}/>
                        <FeaturePage title={"Call Forwarding"} text={callForwardText} img={solutions}/>
                        <FeaturePage title={"SMS Notifications"} text={smsNotificationsText} img={messaging} />                     {/*<SolutionPills/>*/}
                        <FeaturePage title={"Best Pricing Model"} text={payAsYouGoText} img={pricing}/>
                    </MDBRow>
                    <MDBRow>
                        <PricingPage/>
                    </MDBRow>
                    {/*<MDBRow >*/}
                    {/*    <MDBCol className="mt-2">*/}
                    {/*        <MariaTeaser title="Voice" text={solutionText} linkTo="/maria"/>*/}
                    {/*    </MDBCol>*/}
                    {/*</MDBRow>*/}
                    {/*<MDBRow>*/}
                    {/*    <MDBCol className="mt-2">*/}
                    {/*        <MariaTeaser title="Call Forwarding" text={callForwardText} linkTo="/maria"/>*/}
                    {/*    </MDBCol>*/}
                    {/*</MDBRow>*/}
                    {/*<MDBRow>*/}
                    {/*    <MDBCol className="mt-2">*/}
                    {/*        <MariaTeaser title="Pay As You Go" text={priceText} linkTo="/maria"/>*/}
                    {/*    </MDBCol>*/}
                    {/*</MDBRow>*/}
                </MDBContainer>

            </MDBAnimation>
        </>
    )
}

export default VoicePage;