import React, {useContext, useEffect, useState} from 'react';
import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import {Context} from "./BuyPhonesPage";

const ResultTablePnl = (props) => {
    const {phones} = useContext(Context);

    const columns= [
        {
            label: 'Phone',
            field: 'phone',
            sort: 'asc'
        },
        {
            label: 'City',
            field: 'city',
            sort: 'asc'
        },
        {
            label: '',
            field: 'last',
            sort: 'asc'
        },
    ];

    const rows_regular_btn = [
        {
            'phone': (322)-223-3223,
            'city': 'Stockholm',
            // 'last': 'the Bird',
            'handle': <MDBBtn color="purple" size="sm">Button</MDBBtn>
        }
    ];

    useEffect(() => {
        if (phones === null)
        {
            return;
        }
    });

    return(
        <MDBTable btn responsive>
            <MDBTableHead columns={columns} />
            <MDBTableBody rows={phones} />
        </MDBTable>
    );
};

export default ResultTablePnl;