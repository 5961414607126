import {
    MDBAnimation, MDBCardTitle, MDBContainer,
} from 'mdb-react-ui-kit';
import React, {useState} from "react";
import PricingTable from "./PricingTable";
const PricingPage = () => {

    // `useState` returns an array with the state
    // and the method used to update the state
    // You can initialise the state with a variable/object
    const [title, setTitle] = useState('Bronze');

    return (
            <MDBAnimation type='zoomIn' duration='500ms'>
                <MDBContainer fluid>
                    {/*<MDBCardTitle>Pricing</MDBCardTitle>*/}
                    <PricingTable/>
                </MDBContainer>

            </MDBAnimation>
        )


};

export default PricingPage;