import React, {Component, useContext} from "react";

import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBCollapse,
    MDBNavbarItem,
    MDBFooter,
    MDBNavbarLink,
    MDBTooltip,
    MDBIcon,
} from 'mdb-react-ui-kit';
import "./index.css";


// import { ReactComponent as Logo } from './assets/logo.svg';
import {ReactComponent as Logo} from './assets/logo.svg'
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import FooterPage from "./pages/FooterPage";
import { Redirect } from "react-router-dom";
import AlertPage from "./pages/home/AlertPage";
import Dashboard from "./pages/account/Dashboard";

// import { useHistory } from "react-router-dom";
// let history = useHistory();
class App extends Component {
    constructor(props) {
        super(props);

        // Binding https://www.freecodecamp.org/news/react-binding-patterns-5-approaches-for-handling-this-92c651b5af56/
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.closeCollapse = this.closeCollapse.bind(this);
        this.signOut = this.signOut.bind(this);
    }
  state = {
      collapseID: '',
      redirect: false,
      links: {
          nav_items: [
              {
                  name: 'Sign Up',
                  linkTo: '/signup'
              },
              {
                  name: 'Solutions',
                  linkTo: '/'
              },
              {
                  name: 'Add a Line',
                  linkTo: '/addline'
              },
              {
                  name: 'Contact Us',
                  linkTo: '/contactus'
              },
              {
                  name: 'Log In',
                  linkTo: '/signin'
              }
          ]
      }
  }
    componentDidMount() {
        console.log('Mode:' + process.env.NODE_ENV);
    }

    isLoggedIn(){
        return typeof this.props.user !== 'undefined';
    }
    /**
     * Get navigation links based on the conditions, such as whether user is authorized
     * and others
     */
    getNavLinks(){
        let nav_links = this.state.links.nav_items.map( link => {
            let loggedIn =  this.isLoggedIn();

            let name = link.name;
            let linkTo = link.linkTo;

            if (linkTo === '/signin'){
                name = loggedIn ? "Log Out" : "Log In";
            }

            if (linkTo === '/signup'){
                if (name = loggedIn){
                    return null;
                }
            }

            let info = {
                name: name,
                linkTo: linkTo
            };

            return info;
        });

        return nav_links;
    }

  getNavItems (){
      let nav_items = this.state.links.nav_items.map( link => {

          let name = link.name;
          let linkTo = link.linkTo;

          if (linkTo === '/signin'){
              let loggedIn =  this.isLoggedIn();
              name = loggedIn ? "Log Out" : "Log In";

              if (loggedIn){
                  return (
                      <MDBNavbarItem key={name}>
                          <MDBNavbarLink
                              // onClick={this.closeCollapse('mainNavbarCollapse')}
                              onClick={(event) =>
                              {this.signOut(event)}}
                              to='/'
                              href='/'
                          >
                              Log Out
                          </MDBNavbarLink>
                      </MDBNavbarItem>);
              }
          }

          let info = (
              <MDBNavbarItem key={name}>
                  <MDBNavbarLink
                      onClick={this.closeCollapse('mainNavbarCollapse')}
                      to={linkTo}
                      href={linkTo}
                  >
                  {name}
              </MDBNavbarLink>
          </MDBNavbarItem>);

          return info;
      });

      return nav_items;
   }

    toggleCollapse = collapseID => () =>
      this.setState(prevState => ({
        collapseID: prevState.collapseID !== collapseID ? collapseID : ''
      }));

    closeCollapse = collID => () => {
    const { collapseID } = this.state;
    window.scrollTo(0, 0);
    collapseID === collID && this.setState({ collapseID: '' });
    };

    signOut = (event) => {
    // event.preventDefault();
    // this.closeCollapse('mainNavbarCollapse');
    // firebase.auth().signOut();
    // this.setState({ redirect: true });
    }


  render() {
      let emailVerified = typeof this.props.user !== 'undefined';

      if (emailVerified) {
          emailVerified = this.props.user.email_verified;
      }

      const alert = emailVerified ? {} :
          (<AlertPage text={"We sent a verification email. Please click on the link we sent to verify."}/>);

      const overlay = (
          <div
              id='sidenav-overlay'
              style={{backgroundColor: 'transparent'}}
              onClick={this.toggleCollapse('mainNavbarCollapse')}
          />
      );

      const {collapseID} = this.state;
      let redirect = this.state.redirect ? <Redirect to='/'/> : "";
      let nav_items = this.getNavItems();

      let mainBody = this.isLoggedIn() ?

              <Dashboard/>

          :

              <MDBNavbar color="white" light expand='md' fixed='top' scrolling>
                  <MDBNavbarBrand href='/' className='py-0 font-weight-normal'>
                      <Logo style={{ height: '2.5rem', width: '2.5rem' }} />
                      <strong className="font-weight-bold">btelb</strong>
                  </MDBNavbarBrand>

                  <MDBNavbarToggler
                      onClick={this.toggleCollapse('mainNavbarCollapse')}
                  />
                  <MDBCollapse id='mainNavbarCollapse' isopen={collapseID} navbar>
                      <MDBNavbarNav right>
                          {nav_items}
                      </MDBNavbarNav>
                  </MDBCollapse>
              </MDBNavbar>
      {collapseID && overlay};
    return (
        <Router>
              {redirect}
           <div className='flyout white' >

               {mainBody}

              <main style={{ marginTop: '4rem' }}>
                  <Routes />
              </main>
               <div>
                   <FooterPage nav_links={this.getNavLinks()}/>
               </div>
           </div>
        </Router>
    );
  }
}

export default App;
