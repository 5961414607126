import React, {useState} from "react";
import {MDBAnimation} from "mdb-react-ui-kit";

import '../HomePage.css';

import { useHistory } from "react-router-dom";

const FAQPage = ({ title }) => {
    const onClick = (event) => {
        event.preventDefault();
        console.log("on clicked");
    }

    return (
        <>
            <MDBAnimation type='zoomIn' duration='500ms'>
            <div className="container white my-5">
                <h6 className="font-weight-normal text-uppercase font-small grey-text mb-4 text-center">FAQ</h6>
                <h3 className="font-weight-bold black-text mb-4 pb-2 text-center">Frequently Asked Questions</h3>
                <hr className="w-header"/>
                    <p className="lead text-muted mx-auto mt-4 pt-2 mb-5 text-center">Got a question? We've got
                        answers. If you have some other questions, see our support center.</p>

                    <div className="row">
                        <div className="col-md-12 col-lg-10 mx-auto mb-5">

                            <div className="accordion md-accordion" id="accordionEx" role="tablist"
                                 aria-multiselectable="true">

                                <div
                                    className="card border-top border-bottom-0 border-left border-right border-light">

                                    <div className="card-header border-bottom border-light" role="tab"
                                         id="headingOne1">
                                        <a onClick={(event) => {onClick(event)}} data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1"
                                           aria-expanded="true"
                                           aria-controls="collapseOne1">
                                            <h5 className="black-text font-weight-normal mb-0">
                                                Is this a secure site for purchases? <i
                                                className="fas fa-angle-down rotate-icon"></i>
                                            </h5>
                                        </a>
                                    </div>

                                    <div id="collapseOne1" className="collapse show" role="tabpanel"
                                         aria-labelledby="headingOne1"
                                         data-parent="#accordionEx">
                                        <div className="card-body">
                                            Absolutely! We work with top payment companies which guarantees your
                                            safety and security. All billing information is stored on our payment
                                            processing partner which has the most stringent level of certification
                                            available in the payments industry.
                                        </div>
                                    </div>

                                </div>
                                <div className="card border-bottom-0 border-left border-right border-light">

                                    <div className="card-header border-bottom border-light" role="tab"
                                         id="headingTwo2">
                                        <a onClick={(event) => {onClick(event)}} className="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                           href="#collapseTwo2"
                                           aria-expanded="false" aria-controls="collapseTwo2">
                                            <h5 className="black-text font-weight-normal mb-0">
                                                How long are your contracts? <i
                                                className="fas fa-angle-down rotate-icon"></i>
                                            </h5>
                                        </a>
                                    </div>

                                    <div id="collapseTwo2" className="collapse" role="tabpanel"
                                         aria-labelledby="headingTwo2"
                                         data-parent="#accordionEx">
                                        <div className="card-body">
                                            Currently, we only offer monthly subscription. You can upgrade or cancel
                                            your monthly account at any time with no further obligation.
                                        </div>
                                    </div>

                                </div>
                                <div className="card border-bottom-0 border-left border-right border-light">
                                    <div className="card-header border-bottom border-light" role="tab"
                                         id="headingThree3">
                                        <a onClick={(event) => {onClick(event)}} className="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                           href="#collapseThree3"
                                           aria-expanded="false" aria-controls="collapseThree3">
                                            <h5 className="black-text font-weight-normal mb-0">
                                                Can I cancel my subscription? <i
                                                className="fas fa-angle-down rotate-icon"></i>
                                            </h5>
                                        </a>
                                    </div>
                                    <div id="collapseThree3" className="collapse" role="tabpanel"
                                         aria-labelledby="headingThree3"
                                         data-parent="#accordionEx">
                                        <div className="card-body">
                                            You can cancel your subscription anytime in your account. Once the
                                            subscription is cancelled, you will not be charged next month. You will
                                            continue to have access to your account until your current subscription
                                            expires.
                                        </div>
                                    </div>

                                </div>
                                <div className="card border-left border-right border-light">
                                    <div className="card-header border-bottom border-light" role="tab"
                                         id="headingThree4">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                           href="#collapseThree4"
                                           aria-expanded="false" aria-controls="collapseThree4">
                                            <h5 className="black-text font-weight-normal mb-0">
                                                Can I request refund? <i
                                                className="fas fa-angle-down rotate-icon"></i>
                                            </h5>
                                        </a>
                                    </div>

                                    <div id="collapseThree4" className="collapse" role="tabpanel"
                                         aria-labelledby="headingThree4"
                                         data-parent="#accordionEx">
                                        <div className="card-body">
                                            Unfortunately, not. We do not issue full or partial refunds for any
                                            reason.
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
            </div>
            </MDBAnimation>
        </>
    )
}

export default FAQPage;