import React from "react";

class GetStarted extends React.Component {

    state = {
        formActivePanel3: 1,
        formActivePanel1Changed: false,
    }

    swapFormActive = (a) => (param) => (e) => {
        this.setState({
            ['formActivePanel' + a]: param,
            ['formActivePanel' + a + 'Changed']: true
        });
    }

    handleNextPrevClick = (a) => (param) => (e) => {
        this.setState({
            ['formActivePanel' + a]: param,
            ['formActivePanel' + a + 'Changed']: true
        });
    }

    handleSubmission = () => {
        alert('Form submitted!');
    }

    calculateAutofocus = (a) => {
        if (this.state['formActivePanel' + a + 'Changed']) {
            return true
        }
    }

    render() {
        return (
            <div class="row">
                <div class="col-md-12">
                    <ul class="stepper stepper-horizontal">
                        <li class="completed">
                            <a href="#!">
                                <span class="circle">1</span>
                                <span class="label">Find a phone</span>
                            </a>
                        </li>

                        <li class="active">
                            <a href="#!">
                                <span class="circle">2</span>
                                <span class="label">Add Voice Recording (Optional)</span>
                            </a>
                        </li>

                        <li class="active">
                            <a href="#!">
                                <span className="circle">3</span>
                                <span class="label">You are done!</span>
                            </a>
                        </li>

                    </ul>

                </div>
            </div>
        );
    };
}

export default GetStarted;