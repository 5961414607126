import React, {useState} from "react";
import {MDBAnimation, MDBCol, MDBCardGroup, MDBCardImage, MDBCardBody,
    MDBCardTitle, MDBCardText, MDBCard, MDBBtn, MDBContainer, MDBFreeBird,
        MDBJumbotron, MDBRow} from "mdb-react-ui-kit";

import '../HomePage.css';

import { useHistory } from "react-router-dom";
// import ChartsPage from "../charts/ChartsPage";
import FAQPage from "../faq/FAQPage";

const ContactPage = ({ title }) => {


    return (
        <>
            <MDBAnimation type='zoomIn' duration='500ms'>
                <MDBContainer className="container white my-5">
                    <section class="mb-4 ">

                        <h2 class="h1-responsive font-weight-bold text-center my-4">Contact us</h2>
                        <p class="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
                            a matter of hours to help you.</p>

                        <div class="row">

                            <div class="col-md-9 mb-md-0 mb-5">
                                <form id="contact-form" name="contact-form" action="mail.php" method="POST">

                                    <div class="row">

                                        <div class="col-md-6">
                                            <div class="md-form mb-0">
                                                <input type="text" id="name" name="name" class="form-control" placeholder="Your Name"/>
                                                <label for="name" class=""></label>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="md-form mb-0">
                                                <input type="text" id="email" name="email" class="form-control" placeholder="Your Email"/>
                                                <label for="email" class=""></label>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="md-form mb-0">
                                                <input type="text" id="subject" name="subject" class="form-control" placeholder="Subject"/>
                                                <label for="subject" class=""></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">

                                        <div class="col-md-12">

                                            <div class="md-form">
                                                <textarea type="text" id="message" name="message" rows="2" class="form-control md-textarea"></textarea>
                                                <label for="message">Your message</label>
                                            </div>

                                        </div>
                                    </div>

                                </form>

                                <div class="text-center text-md-left">
                                    <a class="btn btn-primary" onclick="document.getElementById('contact-form').submit();">Send</a>
                                </div>
                                <div class="status"></div>
                            </div>

                            <div class="col-md-3 text-center">
                                <ul class="list-unstyled mb-0">
                                    <li><i class="fas fa-map-marker-alt fa-2x"></i>
                                        <p>317 W. Colfax St #109, Palatine, IL, 60067</p>
                                    </li>

                                    <li><i class="fas fa-phone mt-4 fa-2x"></i>
                                        <p>+1 224 622-8018</p>
                                    </li>

                                    <li><i class="fas fa-envelope mt-4 fa-2x"></i>
                                        <p>contact@btelb.com</p>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </section>
                </MDBContainer>
                {/*<FAQPage/>*/}
            </MDBAnimation>
        </>
    )
}

export default ContactPage;