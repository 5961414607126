import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdb-react-ui-kit";

/**
 * Dynamically populate links based on those used in the parents components. For logout
 * we need to use callback function. See https://medium.com/trabe/passing-callbacks-down-with-react-hooks-4723c4652aff
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FooterPage = (props) => {
    const links = props.nav_links.map(link => {
        if (link === null)
            return "";
        let name = link.name;
        let linkTo = link.linkTo;

        if (linkTo.includes('signin'))
            return;

        let info = (
            <li key={name} className="list-unstyled" className="mdb-color-text">
                <a href={linkTo} className="mdb-color-text">{name}</a>
            </li>
        );
        return info;
    });

    return (
        <MDBFooter className="font-small pt-4 mt-4 white mdb-color-text">
            <MDBContainer fluid className="text-center text-md-left " >
                <MDBRow>
                    <MDBCol md="6">
                        <h5 className="title">Resources</h5>
                        <p>
                            Made in USA
                        </p>
                        <ul>
                            <li className="list-unstyled" className="mdb-color-text">
                                <a href="/privacy-policy.html" className="mdb-color-text">Privacy Policy</a>
                            </li>
                            <li className="list-unstyled" className="mdb-color-text">
                                <a href="/terms-and-conditions.html" className="mdb-color-text">Terms of service</a>
                            </li>
                        </ul>
                    </MDBCol>
                    <MDBCol md="6">
                        <h5 className="title">Product</h5>
                        <ul>
                            {links}
                        </ul>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

            <div className="footer-copyright text-center py-3">
                <MDBContainer fluid>
                    &copy; {new Date().getFullYear()} Copyright: <a href="https://btelb.com"> btelb.com </a>
                </MDBContainer>
            </div>
        </MDBFooter>
    );
}

export default FooterPage;